import { HTTP } from '../../axios/axios'

async function getAll () {
  try {
    const res = await HTTP.get('/auth/privilege')
    return res.data
  } catch (error) {
    console.log(error)
  }
}

async function getRoles () {
  try {
    const res = await HTTP.get('/roles')
    return res.data
  } catch (error) {
    console.log(error)
  }
}

async function changeRoles (roles, id) {
  return await HTTP.patch('auth/roles/' + id, roles)
}

export default { getAll, getRoles, changeRoles }
