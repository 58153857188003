  <template>
  <div class="table w-full mb-20">
    <div class="w-full bg-gray-800 text-white rounded-t-lg flex">
      <!-- head -->
      <div class="table-cell font-bold py-6">{{ tableType }}</div>
      <!-- column names -->
      <div
        class="table-cell font-bold"
        v-for="k in columnNames.length"
        :key="k"
      >
        {{ _.capitalize(columnNames[k - 1].name) }}
      </div>
    </div>
    <div class="border border-gray-800">
      <!-- ROW -->
      <div class="row flex w-full" v-for="(i, n) in data" :key="n">
        <!-- Checkmarks -->
        <div class="table-cell font-bold flex justify-around items-center">
          {{ i.name }}
        </div>
        <!-- Cells -->
        <div class="table-cell my-2" v-for="k in columnNames.length" :key="k">
          <label :for="`check-${k}-${n}`" class="checkboxContainer font-bold">
            <input
              type="checkbox"
              :id="`check-${k}-${n}`"
              v-model="data[n][tableType === 'Roles' ? 'permission' : 'roles'][k - 1]"
              @change="$emit('changeData', data, n)"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'Table',
  props: {
    columnNames: Array,
    tableType: String
  },
  data () {
    return {}
  },
  methods: {
  },
  computed: {
    data: {
      get: function () {
        return this.$store.state.data
      },
      set: function (data) {
        this.$store.commit('setData', data)
      }
    },
    _ () {
      return _
    }
  }
}
</script>

<style scoped lang="scss">
.row:nth-child(even) {
  background-color: rgba(31, 41, 55, 0.05);
}
</style>
