<template>
  <div class="users flex item-center justify-center">
    <CheckTable
      v-if="!isFetching"
      :tableType="'Users'"
      :columnNames="columnNames"
      @getData="getData()"
      @changeData="changeData"
    />
    <!-- <div class="absolute flex items-center h-screen80">
      <loader :loading="isFetching" :color="'#1f2937'" :size="'100px'"/>
    </div> -->
  </div>
</template>

<script>
import CheckTable from '@/components/CheckTable.vue'
import requests from '@/services/requests/UsersRequests.js'

export default {
  data () {
    return {
      isFetching: true,
      columnNames: []
    }
  },
  name: 'Users',
  components: {
    CheckTable
  },
  methods: {
    async getData () {
      this.$emit('loading', true)
      let users = await requests.getAll()
      users = Array.from(users, (user) => {
        return {
          name: user.username,
          _id: user._id,
          roles: Array.from(
            this.columnNames,
            (i) => user.roles.indexOf(i.name.toLowerCase()) !== -1
          )
        }
      })
      this.$store.commit('setData', users)
      this.$emit('loading', false)
    },
    changeData (data, n) {
      const payload = {}
      payload.roles = []
      for (let i = 0; i < data[n].roles.length; i++) {
        if (data[n].roles[i]) {
          payload.roles.push(this.columnNames[i].name)
        }
      }
      requests.changeRoles(payload, data[n]._id)
    }
  },
  async created () {
    this.columnNames = await requests.getRoles()
    this.getData().finally(() => (this.isFetching = false))
  }
}
</script>
